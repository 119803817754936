<template>
  <v-layout>
    <app-loader
      title="Загрузка данных Новости"
      :loading="!pageLoaded"
    />
    <v-layout
      v-show="pageLoaded"
      class="d-flex justify-center"
      column
    >
      <v-card
        tile
        class="elevation-1"
        full-width
        :style="{
          height: `${blockHeight + 'px'}`,
          'overflow-y': 'auto'
        }"
      >
        <v-card-title>
          <h1 class="h3 mb-2">
            {{ title }}
          </h1>
        </v-card-title>

        <v-card-text>
          <v-row class="d-flex justify-center">
            <div>
              <v-img
                :src="`${$url}${posterPath}`"
              />
            </div>
          </v-row>
          <v-row>
            <span v-html="value" />
          </v-row>
        </v-card-text>


        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary"
            @click="$router.go(-1)"
          >
            Вернуться к новостям
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-layout>
</template>

<script>
import * as newsApi from "../../backend/newsApi";

export default {
  props: ["link"],
  data() {
    return {
      title: "",
      value: "",
      posterPath: "",
      date: "",
      pageLoaded: false,
    };
  },
  computed: {
    blockHeight() {
      return window.innerHeight - 220;
    },
  },
  created() {
    this.getNews();
  },
  methods: {
    async getNews() {
      this.pageLoaded = false;
      const res = await newsApi.getNews(this.link);
      this.title = res.title;
      this.value = res.value;
      this.posterPath = res.posterPath;
      this.date = res.createdAt;
      this.pageLoaded = true;
    },
  },
};
</script>