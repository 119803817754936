import http from "./http.js";

async function getNewsList(page, pagesize) {
  const response = await http.get("news", {
    params: {
      page,
      pagesize,
    },
  });
  return response.data;
}

async function getNews(link) {
  const response = await http.get(`news/${link}`);
  return response.data;
}

async function markAsReaded(value) {
  await http.post("mark-as-readed-posts", value);
}

export {
  getNewsList,
  getNews,
  markAsReaded,
};